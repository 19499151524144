import * as Html from 'BaxterScript/helper/browser/Html';
import * as State from 'BaxterScript/version/web/core/State';
import * as Objects from 'BaxterScript/helper/object/Object';
import * as Strings from 'BaxterScript/helper/string/String';
import { buildSlotRenderedEvent } from 'BaxterScript/version/web/core/SlotRenderedEventListener';
import {
  videoJSCreateTags,
  videoJSDefaultOptions,
  videoJSInitializeAtFirstClick,
  videoJSPlay,
  videoJSPlayer,
  videoJSRemoveControls,
  videoJSScriptDependencies,
  videoJSStyleDependencies,
} from 'BaxterScript/version/web/provider/googleima/VideoJS';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { GoogleImaSlot } from 'BaxterScript/types/Slot';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import {
  GoogleImaConfig,
  GoogleImaCoreConfig,
  GoogleImaPrebidConfig,
  GoogleImaTargetingConfig,
} from 'BaxterScript/types/ProviderSettings/GoogleIma';
import { Config } from 'BaxterScript/types/Config';
import Prebid from './GoogleIMAPrebid';
import { addTargeting } from './TransformAdTag';
import { transformTargeting } from '../TransformTargeting';

export const id = Providers.GOOGLE_IMA;
const GOOGLE_ADS_ID = Providers.GOOGLE_ADS;

export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  );

export const init = () => {
  console.info('[SLOTS][GOOGLEIMA][INIT]');
  if (Prebid) {
    Prebid.init();
  }
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEIMA][LOADED]');
  if (Prebid) {
    Prebid.loaded();
  }
};

export const transform = async (
  pageId: string,
  containerId: string,
  slotId: string,
  params: Record<string, unknown>
): Promise<Partial<GoogleImaSlot>> => {
  console.info('[SLOTS][GOOGLEIMA][TRANSFORM]', pageId, containerId, slotId, params);
  let slot = {
    targeting: {},
  } as Partial<GoogleImaSlot>;
  const providerConfig = globalThis.Baxter.config.providers[GOOGLE_ADS_ID]?.settings || {};
  const providerSettings = (globalThis.Baxter.config.slots?.providerSettings?.[id] || {}) as GoogleImaConfig;
  transformTargeting(slot, providerSettings, providerConfig, pageId, containerId, slotId, params);
  const prebidSettings =
    // @ts-ignore
    (globalThis.Baxter.context.configurationService.getById(providerSettings.prebid, pageId, containerId, slotId) ||
      {}) as GoogleImaPrebidConfig;
  if (Prebid && prebidSettings.enabled) {
    const { accountId } = providerConfig;
    const pathPrefixMap = providerConfig.pathPrefix;
    const pathPrefix = Strings.parseMap(pathPrefixMap || [], params);
    slot.path = pathPrefix
      ? `/${accountId}/${pathPrefix}/${prebidSettings.path}`
      : `/${accountId}/${prebidSettings.path}`;

    slot = { ...slot, ...(await Prebid.transform(pageId, containerId, slotId, params)) };
  }
  const core = (globalThis.Baxter.context.configurationService.getById(
    providerSettings.core,
    pageId,
    containerId,
    slotId
  ) || {}) as GoogleImaCoreConfig;
  const targeting =
    globalThis.Baxter.context.configurationService.getById(providerSettings.targeting, pageId, containerId, slotId) ||
    ({} as GoogleImaTargetingConfig);
  // @ts-ignore
  return {
    // @ts-ignore
    [id]: {
      config: {
        core,
        targeting,
        prebid: prebidSettings,
      },
      state: {},
    },
    ...slot,
  };
};

export const styleDependencies = () => {
  console.info('[SLOTS][GOOGLEIMA][STYLEDEPENDENCIES]');
  const version = globalThis.Baxter.config.providers[id]?.videoJsIMAVersion;
  const dependencies = videoJSStyleDependencies(globalThis.Baxter.config.cdnDomain, version);
  return {
    id,
    dependencies,
  };
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEIMA][DEPENDENCIES]');
  const version = globalThis.Baxter.config.providers[id]?.videoJsIMAVersion;
  const videoDependencies = videoJSScriptDependencies(globalThis.Baxter.config.cdnDomain, version);
  if (Prebid) {
    videoDependencies.push(...Prebid.dependencies());
  }
  return {
    id,
    dependencies: videoDependencies,
  };
};

export const createVideoTag = (slot: GoogleImaSlot, hidden: boolean) => {
  console.info('[SLOTS][GOOGLEIMA][CREATEVIDEOTAG]', slot);
  if (hidden) {
    Html.hideElement(slot.innerId);
  }
  videoJSCreateTags(
    slot.innerId,
    slot[id].state.innerVideoId,
    slot[id].config.core.posterUrl,
    slot[id].config.core.sources,
    globalThis.Baxter.config.cdnDomain
  );
};

export const play = (slot: GoogleImaSlot, player) => {
  console.info('[SLOTS][GOOGLEIMA][PLAY]', slot);
  if (slot[id].config.core.autoplay && !slot[id].state.autoplayed) {
    console.debug('[SLOTS][GOOGLEIMA][PLAY] autoplay');
    newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_AUTOPLAY);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.autoplayed = true;
    videoJSPlay(player);
  }
};

export const addEvents = (slot: GoogleImaSlot, player) => {
  console.info('[SLOTS][GOOGLEIMA][ADDEVENTS]', slot);
  player.on('adserror', (err) => {
    try {
      console.info('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK]', err);
      newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_ADS_ERROR);
      if (slot[id].state.fallbackRequested) {
        console.debug('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK] do nothing as fallback already requested');
        return;
      }
      // eslint-disable-next-line no-param-reassign
      slot[id].state.adsError = true;
      if (slot[id].state.fallbackEnabled) {
        if (!slot[id].state.fallbackRequested) {
          console.debug('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK] fallback');
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_ADSERROR_FALLBACK);
          // eslint-disable-next-line no-param-reassign
          slot[id].state.fallbackRequested = true;
          window.dispatchEvent(
            buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, true, id)
          );
        }
      } else if (slot[id].state?.shouldAutoplay) {
        console.debug('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK] play');
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_PLAY);
        play(slot, player);
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEIMA][ADSERRORCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
        command: '[ADSERRORCALLBACK]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
  player.on('error', () => {
    try {
      console.info('[SLOTS][GOOGLEIMA][ERRORCALLBACK] play', player?.error());
      newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_PLAYER_ERROR, { playerErrorCode: player?.error()?.code });
      if (slot[id].state.fallbackRequested) {
        console.debug('[SLOTS][GOOGLEIMA][ERRORCALLBACK] do nothing as fallback already requested');
        return;
      }
      if (player.error().code === 4) {
        // Error 4 is video didn't load for some reason
        if (slot[id].state.fallbackEnabled) {
          if (!slot[id].state.fallbackRequested) {
            console.debug('[SLOTS][GOOGLEIMA][ERRORCALLBACK] fallback');
            newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_ERROR_FALLBACK);
            // eslint-disable-next-line no-param-reassign
            slot[id].state.fallbackRequested = true;
            window.dispatchEvent(
              buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, true, id)
            );
          }
        } else {
          console.debug('[SLOTS][GOOGLEIMA][ERRORCALLBACK] reseting player');
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_ERROR_RESETING_PLAYER);
          player.dispose();
          createVideoTag(slot, false);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          loadVideo(slot);
        }
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEIMA][ERRORCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
        command: '[ERRORCALLBACK]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
  player.on('adsready', () => {
    try {
      console.info('[SLOTS][GOOGLEIMA][ADSREADYCALLBACK]');
      newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_ADS_READY);
      if (slot[id].state.fallbackRequested) {
        console.debug('[SLOTS][GOOGLEIMA][ADSREADYCALLBACK] do nothing as fallback already requested');
        return;
      }
      // eslint-disable-next-line no-param-reassign
      slot[id].state.adsReady = true;
      Html.showElement(slot.innerId);
      window.dispatchEvent(buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, false, id));
    } catch (e) {
      console.error('[SLOTS][GOOGLEIMA][ADSREADYCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
        command: '[ADSREADYCALLBACK]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
  // eslint-disable-next-line no-param-reassign
  slot[id].state.fallbackTimeout = window.setTimeout(() => {
    try {
      if (!slot[id].state.adsReady && slot[id].state.fallbackEnabled && !slot[id].state.fallbackRequested) {
        console.debug('[SLOTS][GOOGLEIMA][TIMEOUT]');
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_TIMEOUT_FALLBACK);
        // eslint-disable-next-line no-param-reassign
        slot[id].state.fallbackRequested = true;
        window.dispatchEvent(buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, true, id));
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEIMA][FALLBACKTIMEOUT]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
        command: '[FALLBACKTIMEOUT]',
        message: (e as Error).message,
      });
    }
  }, 1000);
};

export const loadVideo = (slot: GoogleImaSlot, retry = 1) => {
  const urls = dependencies().dependencies.map((dependency) => dependency.url);
  if (State.areDependenciesResolved(urls)) {
    console.debug('[SLOTS][GOOGLEIMA][LOADVIDEO] dependencies resolved');
    newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_LOAD);
    if (!State.areDependenciesResolvedWithSuccess(urls)) {
      console.debug('[SLOTS][GOOGLEIMA][LOADVIDEO] dependencies resolved with errors so fallback');
      if (slot[id].state.fallbackEnabled) {
        if (!slot[id].state.fallbackRequested) {
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_DEPENDENCIES_FALLBACK);
          // eslint-disable-next-line no-param-reassign
          slot[id].state.fallbackRequested = true;
          window.dispatchEvent(
            buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, true, id)
          );
        }
      }
      return;
    }
    if (!slot[id].state.fallbackEnabled) {
      Html.showElement(slot.innerId);
    }
    console.debug('[SLOTS][GOOGLEIMA][LOADVIDEO] dependencies resolved without errors');
    const player = videoJSPlayer(slot[id].state.innerVideoId, { muted: !!slot[id].config.core.autoplay });
    addEvents(slot, player);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.playerInitialized = true;
    const imaOptions = videoJSDefaultOptions(slot[id].state.innerVideoId, slot[id].config.core.adLabel);
    imaOptions.adsManagerLoadedCallback = () => {
      try {
        console.debug('[SLOTS][GOOGLEIMA][ADSMANAGERLOADEDCALLBACK]');
        newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_ADS_MANAGER_LOADED);
        if (slot[id].state.fallbackRequested) {
          console.debug('[SLOTS][GOOGLEIMA][ADSMANAGERLOADEDCALLBACK] do nothing as fallback already requested');
          return;
        }
        // eslint-disable-next-line no-param-reassign
        slot[id].state.adsManagerLoaded = true;
        if (slot[id].state?.shouldAutoplay) {
          console.debug('[SLOTS][GOOGLEIMA][ADSMANAGERLOADEDCALLBACK] play');
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_PLAY);
          play(slot, player);
        }
      } catch (e) {
        console.error('[SLOTS][GOOGLEIMA][ADSMANAGERLOADEDCALLBACK]', e);
        newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
          command: '[ADSMANAGERLOADEDCALLBACK]',
          message: (e as Error).message,
        });
        throw e;
      }
    };
    if (slot.prebidAdTagUrl || slot[id].config.core.adTagUrl) {
      // eslint-disable-next-line no-param-reassign
      slot[id].state.adTagUrlWithCustParams = addTargeting(
        slot.prebidAdTagUrl || slot[id].config.core.adTagUrl,
        slot.targeting
      );
      imaOptions.adTagUrl = slot[id].state.adTagUrlWithCustParams;
    } else {
      imaOptions.adsResponse =
        '<VAST xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="vast.xsd" version="3.0"/>';
    }
    if (typeof player.ima === 'function') {
      player.ima(imaOptions);
    }
    videoJSRemoveControls(slot[id].state.innerVideoId);
    videoJSInitializeAtFirstClick(slot[id].state.innerVideoId, player);
  } else {
    console.debug('[SLOTS][GOOGLEIMA][LOADVIDEO] DEPENDENCIES NOT YET LOADED');
    if (retry <= 20) {
      // eslint-disable-next-line no-param-reassign
      slot[id].state.dependenciesTimeout = window.setTimeout(() => {
        try {
          loadVideo(slot, retry + 1);
        } catch (e) {
          console.error('[SLOTS][GOOGLEIMA][DEPENDENCIESTIMEOUT]', e);
          newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_ERROR, {
            command: '[DEPENDENCIESTIMEOUT]',
            message: (e as Error).message,
          });
        }
      }, 100 * retry);
    } else {
      console.error('[SLOTS][GOOGLEIMA][LOADVIDEO] dependencies not resolved so fallback');
      newRelicMetrics.reportError(NewRelicError.GOOGLEIMA_DEPENDENCIES_NOT_RESOLVED, State.getPageParams());
      if (slot[id].state.fallbackEnabled) {
        if (!slot[id].state.fallbackRequested) {
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_DEPENDENCIES_FALLBACK);
          // eslint-disable-next-line no-param-reassign
          slot[id].state.fallbackRequested = true;
          window.dispatchEvent(
            buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, true, id)
          );
        }
      }
    }
  }
};

export const create = async (slot: GoogleImaSlot): Promise<unknown> => {
  console.info('[SLOTS][GOOGLEIMA][CREATE]', slot);
  // eslint-disable-next-line no-param-reassign
  slot[id].state.innerVideoId = `${slot.innerId}-video`;
  // eslint-disable-next-line no-param-reassign
  slot[id].state.fallbackEnabled = globalThis.Baxter.context.configurationService.isFallbackEnabledFor(
    slot.pageId,
    slot.containerId
  );
  createVideoTag(slot, true);
  if (slot[id].config.prebid.enabled) {
    await Prebid.create(slot, loadVideo);
  } else {
    loadVideo(slot);
  }
  return true;
};

export const remove = (slots: GoogleImaSlot[] = []) => {
  console.info('[SLOTS][GOOGLEIMA][REMOVE]', slots);
  if (Prebid) {
    Prebid.remove(slots);
  }
  slots.forEach((slot) => {
    clearTimeout(slot[id].state.dependenciesTimeout);
    clearTimeout(slot[id].state.fallbackTimeout);
    if (slot[id].state.playerInitialized) {
      videoJSPlayer(slot[id].state.innerVideoId).dispose();
    }
    Html.clearElement(slot.innerId);
  });
  return true;
};

export const setPageTargeting = () => {
  console.info('[SLOTS][GOOGLEIMA][SETPAGETARGETING]');
  const providerSettings = globalThis.Baxter.config.providers[GOOGLE_ADS_ID] || {};
  const settings = providerSettings.settings || {};
  if (Array.isArray(settings.targeting)) {
    const params = State.getPageParams();
    const ranges = globalThis.Baxter.config.app?.ranges;
    const targeting = Objects.parseMap(settings.targeting || [], params, ranges);
    if (Prebid) {
      Prebid.setTargeting(targeting);
    }
  }
};

export const refresh = async (slots: GoogleImaSlot[]): Promise<boolean> => {
  console.info('[SLOTS][GOOGLEIMA][REFRESH]', slots);
  return true;
};

export const clear = () => {
  console.info('[SLOTS][GOOGLEIMA][CLEAR]');
  const slots = State.getSlots();
  const videoJsImaSlots = Object.keys(slots)
    .filter((containerId) => slots[containerId].provider === id)
    .map((containerId) => slots[containerId]) as GoogleImaSlot[];
  videoJsImaSlots.forEach((slot) => {
    clearTimeout(slot[id].state.dependenciesTimeout);
    clearTimeout(slot[id].state.fallbackTimeout);
  });
  remove(videoJsImaSlots);
  setPageTargeting();
  return true;
};

export const autoplay = (slot: GoogleImaSlot) => {
  // eslint-disable-next-line no-param-reassign
  slot[id].state.shouldAutoplay = true;
  if (slot[id].state.adsManagerLoaded || slot[id].state.adsError) {
    console.debug('[SLOTS][GOOGLEIMA][AUTOPLAY] play');
    newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEIMA_PLAY);
    play(slot, videoJSPlayer(slot[id].state.innerVideoId));
  }
};
